// third-party components, hooks, libraries and packages
import { useState } from "react";

// components
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// styles
import "../styles/page_edit_coordinator.css"
import { Button, Divider, FormContainer, InputField } from "../components/form_elements.jsx";

export default function PageEditCoordinator () {
    // state holders
    const formData = useState({})

    return (
        <LAYOUT_DEFAULT pageName="edit_coordinator">
            <section>
                <div className="title">Edit coordinator</div>

                <FormContainer context={ formData }>
                    <div className="input-group">
                        <InputField label="First Name" id="input_firstName" type="text" />
                        <InputField label="Last Name" id="input_lastName" type="text" />
                    </div>
                    <Divider />
                    <div className="input-group">
                        <InputField label="Phone" id="input_phone" type="tel" />
                        <InputField label="Email" id="input_email" type="email" />
                    </div>
                    <Divider />
                    <InputField label="Picture" id="input_picture" type="file" />
                    <Button label={ "Add Coordinator" } />
                </FormContainer>
            </section>
        </LAYOUT_DEFAULT>
    )
}