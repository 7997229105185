// components
import { CARD_PRODUCT } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_RIBBON } from "../components/navs.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.png"
import ICON_SEARCH from "../images/search.svg"

// styles
import "../styles/page_products.css"

export default function PageProducts () {
    return (
        <LAYOUT_DEFAULT pageName="products">
            <section>
                <NAV_RIBBON>
                    <form>
                        <input type="search" name="input_search" id="input_search" placeholder="Search by name" title="Search by name" />
                        <button><img src={ ICON_SEARCH } alt="search" /></button>
                    </form>
                    <div className="tab">Hats</div>
                    <div className="tab">Tops</div>
                    <div className="tab">Bottoms</div>
                    <div className="tab">Footwear</div>
                    <div className="tab">Accessories</div>
                    <div className="tab">Cloths and Fabrics</div>
                </NAV_RIBBON>
                <div className="title">Products</div>
                <div id="collection" className="grid">
                    <CARD_PRODUCT image={ HOLDING_IMAGE } name="Product" price="$ 57.95" inventory="75" />
                    <CARD_PRODUCT image={ HOLDING_IMAGE } name="Product" price="$ 57.95" inventory="75" />
                    <CARD_PRODUCT image={ HOLDING_IMAGE } name="Product" price="$ 57.95" inventory="75" />
                    <CARD_PRODUCT image={ HOLDING_IMAGE } name="Product" price="$ 57.95" inventory="75" />
                    <CARD_PRODUCT image={ HOLDING_IMAGE } name="Product" price="$ 57.95" inventory="75" />
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}