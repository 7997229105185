// third-party components and hooks
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom"

// components
import { CARD_USER } from "./cards";

// graphics
import ARROW_LEFT from "../images/arrow_left.svg"
import CHEVRON_DOWN from "../images/chevron_down.svg"
import SEARCH from "../images/search.svg"

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"

// data
import { NAV } from "../data/nav";

// styles
import "../styles/navs.css"

// dynamic nav bar
export function NAV_DYNAMIC (props) {
    // state holders
    const location = useLocation();
    const navigate = useNavigate();

    // lambda(s)
    const expandLinkCombo = (index) => { document.querySelector(`#dynamic .body .links`).children[index].classList.toggle("expanded") }
    const expandNav = () => { document.querySelector(`#dynamic`).classList.toggle("expanded") }
    const signOut =() => { console.log("Signing out"); }

    return (
        <div className="nav" id="dynamic">
            <div className="head">
                {
                    location?.pathname === "/dashboard" ?
                    <div className="brand">Admin</div> :
                    <div className="back" onClick={ () => navigate(-1) }><img src={ ARROW_LEFT } alt="Back" /></div>
                }
                <div className="menu" onClick={ expandNav }>MENU</div>
            </div>

            <div className="body">
                <div className="links">
                    {
                        NAV?.map((navlink, index) => {
                            return (
                                navlink?.subs ?
                                <div className="link-combo" key={ index }>
                                    <div className="dom">
                                        <NavLink to={ { pathname: navlink?.path } } className="link" isactive="active">{ navlink?.name }</NavLink>
                                        <div className="controller" onClick={ () => expandLinkCombo(index) }><img src={ CHEVRON_DOWN } alt="drop down" /></div>
                                    </div>
                                    <div className="subs">
                                        {
                                            navlink?.subs?.map((sub, subIndex) => {
                                                return (
                                                    <NavLink to={ { pathname: sub?.path } } className="link" isactive="active" key={ subIndex }>{ sub?.name }</NavLink>
                                                )
                                            })
                                        }
                                    </div>
                                </div> :
                                <NavLink to={ { pathname: navlink?.path } } className="link" isactive="active" key={ index }>{ navlink?.name }</NavLink>
                            )
                        })
                    }
                </div>

                <div className="account">
                    <CARD_USER image={ HOLDING_IMAGE } name={ "John Doe" } callback={ signOut } />
                </div>
            </div>
        </div>
    )
}

// ribbon, in-page nav bar
export function NAV_RIBBON (props) {
    return (
        <div className="nav" id="ribbon">
            {
                props?.searchBar ?
                <form>
                    <input type="search" name="input_query" id="input_query" placeholder={ props?.searchBarPlaceholder } />
                    <button onClick={ (e) => props?.searchBarCallback(e, document.getElementById("input_query")?.value) }><img src={ SEARCH } alt="search button" /></button>
                </form> : null
            }
            { props?.children }
        </div>
    )
}

// switch bar
export function NAV_SWITCH_BAR (props) {
    // state holders
    const [active, setActive] = useState(props?.options[props?.defaultIndex])

    // lambda
    const switched = (option) => {
        setActive(option)
        return props?.callback(option?.id)
    }

    return (
        <div className="nav switch-bar">
            <div className="active">{ active?.name }</div>
            <div className="options">
                {
                    props?.options?.map((option, key) => {
                        return (
                            <div key={ key } onClick={ () => switched(option) }>{ option.name }</div>
                        )
                    })
                }
            </div>
        </div>
    )
}