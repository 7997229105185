// third-party components and hooks
import { BrowserRouter, Route, Routes } from "react-router-dom";

// pages
import PageAttendeeDetails from "./pages/attendee_details";
import PageAttendees from "./pages/attendees";
import PageCoordinatorDetails from "./pages/coordinator_details";
import PageCoordinators from "./pages/coordinators";
import PageDashboard from './pages/dashboard';
import PageEditCoordinator from "./pages/edit_coordinator";
import PageEditEvent from "./pages/edit_event";
import PageEditVendor from "./pages/edit_vendor";
import PageEventDetails from "./pages/event_details";
import PageEvents from "./pages/events";
import PageLogIn from "./pages/log_in";
import PagePayments from "./pages/payments";
import PageProductDetails from "./pages/product_details";
import PageProducts from "./pages/products";
import PagePurchases from "./pages/purchases";
import PageSessions from "./pages/sessions";
import PageTransactions from "./pages/transactions";
import PageVendorDetails from "./pages/vendor_details";
import PageVendors from "./pages/vendors";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={ PageLogIn } />
        <Route path="/attendee_details" Component={ PageAttendeeDetails } />
        <Route path="/attendees" Component={ PageAttendees } />
        <Route path="/attendees/:id" Component={ PageAttendees } />
        <Route path="/coordinator_details" Component={ PageCoordinatorDetails } />
        <Route path="/coordinators" Component={ PageCoordinators } />
        <Route path="/coordinators/:id" Component={ PageCoordinators } />
        <Route path="/dashboard" Component={ PageDashboard } />
        <Route path="/edit_coordinator" Component={ PageEditCoordinator } />
        <Route path="/edit_event" Component={ PageEditEvent } />
        <Route path="/edit_vendor" Component={ PageEditVendor } />
        <Route path="/event_details" Component={ PageEventDetails } />
        <Route path="/events" Component={ PageEvents } />
        <Route path="/events/:id" Component={ PageEvents } />
        <Route path="/payments" Component={ PagePayments } />
        <Route path="/product_details" Component={ PageProductDetails } />
        <Route path="/products" Component={ PageProducts } />
        <Route path="/purchases" Component={ PagePurchases } />
        <Route path="/sessions" Component={ PageSessions } />
        <Route path="/transactions" Component={ PageTransactions } />
        <Route path="/transactions/:id" Component={ PageTransactions } />
        <Route path="/vendor_details" Component={ PageVendorDetails } />
        <Route path="/vendors" Component={ PageVendors } />
        <Route path="/vendors/:id" Component={ PageVendors } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
