// components
import { CARD_TRANSACTION } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_RIBBON } from "../components/navs.jsx";

// styles
import "../styles/page_products.css"

export default function PageTransactions () {
    return (
        <LAYOUT_DEFAULT pageName="transactions">
            <section>
                <NAV_RIBBON>
                    <div className="tab">Successfult</div>
                    <div className="tab">Pending</div>
                    <div className="tab">Failed</div>
                </NAV_RIBBON>

                <div id="collection-head">
                    <div className="title">Transactions</div>
                </div>
                <div id="collection" className="list">
                    <CARD_TRANSACTION heading={ true } />
                    <CARD_TRANSACTION id={ "1019121" } datetime={ "11/11/11" } amount={ "$ 908.14" } sender={ "1209832" } receiver={ "8434321" } status={ "Successful" } reason={ "" } />
                    <CARD_TRANSACTION id={ "1019121" } datetime={ "11/11/11" } amount={ "$ 908.14" } sender={ "1209832" } receiver={ "8434321" } status={ "Successful" } reason={ "" } />
                    <CARD_TRANSACTION id={ "1019121" } datetime={ "11/11/11" } amount={ "$ 908.14" } sender={ "1209832" } receiver={ "8434321" } status={ "Successful" } reason={ "" } />
                    <CARD_TRANSACTION id={ "1019121" } datetime={ "11/11/11" } amount={ "$ 908.14" } sender={ "1209832" } receiver={ "8434321" } status={ "Successful" } reason={ "" } />
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}