// components
import { CARD_SESSION } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// styles
import "../styles/page_products.css"

export default function PageSessions () {
    return (
        <LAYOUT_DEFAULT pageName="sessions">
            <section>
                <div id="collection-head">
                    <div className="title">Sessions</div>
                    <div className="title">3 Devices</div>
                </div>
                <div id="collection" className="list">
                    <CARD_SESSION heading={ true } />
                    <CARD_SESSION datetime={ "11/11/11" } location={ "Accra" } client={ "Chrome" } duration={ "1hr" } />
                    <CARD_SESSION datetime={ "11/11/11" } location={ "Accra" } client={ "Chrome" } duration={ "1hr" } />
                    <CARD_SESSION datetime={ "11/11/11" } location={ "Accra" } client={ "Chrome" } duration={ "1hr" } />
                    <CARD_SESSION datetime={ "11/11/11" } location={ "Accra" } client={ "Chrome" } duration={ "1hr" } />
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}