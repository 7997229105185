// components
import { CARD_COORDINATOR, CARD_ORGANISER, CARD_SPEAKER } from "../components/cards.jsx";
import { FORM_EDIT_EVENT } from "../components/forms.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.png"
import HOLDING_IMAGE_2 from "../images/PXL_20220909_235830633.PORTRAIT.jpg"

// styles
import "../styles/page_event_details.css"

export default function PageEventDetails () {
    // lambda
    const toggleContentPannel = () => { document.querySelector(".layout.default")?.classList?.toggle("show-pannel") }
    const updateEvent = () => { console.log("updating event"); }

    return (
        <LAYOUT_DEFAULT pageName="event_details" type="panneled">
            <section id="main">
                <div className="banner">
                    <div className="flier" style={ { "--BACKDROP-IMAGE": HOLDING_IMAGE_2 } }><img src={ HOLDING_IMAGE_2 } alt="" /></div>
                    <div className="cta" onClick={ () => toggleContentPannel() }>Update/ Edit</div>
                </div>
                <div className="details">
                    <div className="left">
                        <div className="headline">
                            <div className="title">Description</div>
                            <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt ex itaque qui voluptatum. Id perspiciatis dolorem alias, repellendus porro asperiores nostrum facilis quod consequuntur aliquam in, perferendis similique, quisquam vero.</div>
                        </div>
                        
                        <div className="headline">
                            <div className="title">Speakers and Guests</div>
                            <div className="list h n-wrap">
                                <CARD_SPEAKER image={ HOLDING_IMAGE } name={ "Speaker" } organisation={ "Speaker Inc." } />
                                <CARD_SPEAKER image={ HOLDING_IMAGE } name={ "Speaker" } organisation={ "Speaker Inc." } />
                                <CARD_SPEAKER image={ HOLDING_IMAGE } name={ "Speaker" } organisation={ "Speaker Inc." } />
                            </div>
                        </div>
                        <div className="headline">
                            <div className="title">Coordinators</div>
                            <div className="list v">
                                <CARD_COORDINATOR />
                                <CARD_COORDINATOR />
                            </div>
                        </div>
                        <div className="headline">
                            <div className="title">Organisers</div>
                            <div className="list h n-wrap">
                                <CARD_ORGANISER logo={ HOLDING_IMAGE } name={ "Organiser" } />
                                <CARD_ORGANISER logo={ HOLDING_IMAGE } name={ "Organiser" } />
                                <CARD_ORGANISER logo={ HOLDING_IMAGE } name={ "Organiser" } />
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="headline">
                            <div className="title">Dates and Time</div>
                            <div className="list v">
                                <div>12/12/2012, 12:12pm</div>
                                <div>12/12/2012, 12:12pm</div>
                                <div>12/12/2012, 12:12pm</div>
                            </div>
                        </div>
                        <div className="headline">
                            <div className="title">Venues</div>
                            <div className="list v">
                                <div>Someplace</div>
                                <div>Someplace</div>
                                <div>Someplace</div>
                            </div>
                        </div>
                        <div className="headline">
                            <div className="title">Rates</div>
                            <div className="list v">
                                <div>$ 50 Early Bird Regular</div>
                                <div>$ 80 Regular</div>
                                <div>$ 100 Early Bird VIP</div>
                                <div>$ 150 VIP</div>
                            </div>
                        </div>
                        <div className="headline">
                            <div className="title">Tags</div>
                            <div className="list h">
                                <div>#Event</div>
                                <div>#Event</div>
                                <div>#Event</div>
                                <div>#Event</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pannel">
                <FORM_EDIT_EVENT callback={ updateEvent } exit={ toggleContentPannel } />
            </section>
        </LAYOUT_DEFAULT>
    )
}