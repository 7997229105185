// components
import { CARD_ATTENDEE } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_RIBBON } from "../components/navs.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_attendees.css"

export default function PageAttendees () {
    // lamdda
    const search = (action, query) => {
        action.preventDefault();
        console.log(query);
    }

    return (
        <LAYOUT_DEFAULT pageName="attendees">
            <section>
                <NAV_RIBBON searchBar={ true } searchBarPlaceholder="Search by name" searchBarCallback={ search }>
                    <div className="tab">Active</div>
                    <div className="tab">Inactive</div>
                </NAV_RIBBON>

                <div id="collection-head">
                    <div className="title">Attendees</div>
                </div>

                <div id="collection" className="grid">
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                    <CARD_ATTENDEE image={ HOLDING_IMAGE } name={ "John Doe" } id={ "1" } />
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}