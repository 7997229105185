// third-party components, hooks, libraries and packages
import { Link } from "react-router-dom";

// components
import { CARD_CHART, CARD_EVENT_FLIER, CARD_EVENT_TICKET_SALES, CARD_PRODUCT_RECORD } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_SWITCH_BAR } from "../components/navs.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.png"

// styles
import "../styles/page_dashboard.css"

export default function Dashboard () {
    // functions
    const switchEvents = () => { console.log("switched events"); }

    return (
        <LAYOUT_DEFAULT pageName="dashboard">
            <section>
                <div id="transactions">
                    <div className="heading">TRANSACTIONS</div>
                    <div className="successful">
                        <div className="emphasize">$ 23,020,122.98</div>
                        <div>worth of transactions made</div>
                        <div>from</div>
                        <div className="emphasize">336,865,963</div>
                        <div>successful payments processed</div>
                    </div>
                    <div className="attempts">
                        <div className="emphasize">416,865,963</div>
                        <div>total payments received</div>
                        <div className="emphasize">80,000</div>
                        <div>failed payments</div>
                    </div>
                    <Link to={ { pathname: "/transactions" } } className="more">Explore More on Transactions</Link>
                </div>

                <div id="users">
                    <div className="heading">USERS</div>
                    <div className="chart">
                        Vendors Chart, has 3 line charts, one color for all vendors and another color for active vendors and a gray color for inactive vendors
                    </div>
                    <div className="chart">
                        Attendees Chart, has 3 line charts, one color for all attendees and another color for active attendees and a gray color for inactive attendees
                    </div>
                </div>

                <div id="events-and-tickets">
                    <div className="heading">EVENTS AND TICKETS</div>
                    {/* for events */}
                    <main>
                        <div className="title">Upcoming Event</div>
                        <div><CARD_EVENT_FLIER image={ HOLDING_IMAGE } /></div>
                        <div>Total number of tickets sold for this event: 534</div>
                    </main>
                    {/* for tickets */}
                    <aside>
                        <div className="summary">
                            <div>Total Events: 18</div>
                            <div>Total Tickets Sold: 27319</div>
                        </div>
                        <div className="title">Recent Past Events</div>
                        <div className="list">
                            <CARD_EVENT_TICKET_SALES name="Event Name" tickets_sold="524" />
                            <CARD_EVENT_TICKET_SALES name="Event Name" tickets_sold="524" />
                            <CARD_EVENT_TICKET_SALES name="Event Name" tickets_sold="524" />
                        </div>
                    </aside>
                </div>

                <div id="market">
                    <div className="heading">MARKETPLACE</div>
                    <div className="chart-container">
                        {/* Market Growth Chart, showing graph of changes in number of products listed on the site */}
                        <CARD_CHART />
                    </div>
                    <div className="products-list">
                        <NAV_SWITCH_BAR options={ [
                            {
                                name: "Newest Product",
                                id: "0"
                            }, {
                                name: "Modified Product",
                                id: "1"
                            },
                        ] }
                        defaultIndex={ 0 } callback={ switchEvents } />

                        <div id="collection" className="list">
                            <CARD_PRODUCT_RECORD image={ HOLDING_IMAGE } name={ "product name" } type="update" date="11/11/11" />
                            <CARD_PRODUCT_RECORD image={ HOLDING_IMAGE } name={ "product name" } type="update" date="11/11/11" />
                            <CARD_PRODUCT_RECORD image={ HOLDING_IMAGE } name={ "product name" } type="update" date="11/11/11" />
                        </div>
                    </div>
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}