// third-party components, hooks, libraries and packages
import { useState } from "react";

// components
import { Button, Divider, FormContainer, InputField } from "../components/form_elements.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// styles
import "../styles/page_edit_event.css"

export default function PageEditEvent () {
    // state holders
    const formData = useState({})

    return (
        <LAYOUT_DEFAULT pageName="edit_event">
            <section>
                <div className="title">Edit event</div>

                <FormContainer context={ formData }>
                    <InputField label="Name" id="input_name" type="text" />
                    <InputField label="Venue" id="input_venue" type="select" />
                    <InputField label="Days Duration" id="input_dayDuration" type="number" />
                    <InputField label="Date(s)" id="input_date" type="date" />
                    <InputField label="Hashtag(s)" id="input_hashtag" type="text" />
                    
                    <Divider />
                    <InputField label="Event Phone" id="input_eventPhone" type="tel" />
                    <InputField label="Event Email" id="input_eventEmail" type="email" />
                    
                    <Divider />
                    <InputField label="Event Logo" id="input_eventLogo" type="file" />
                    <InputField label="Event Flier" id="input_eventFlier" type="file" />
                    <InputField label="Event Socials" type="none" /> {/* should be label only, create a new component called label */}
                    <InputField label="Twitter" id="input_eventTwitter" type="url" />
                    <InputField label="Instagram" id="input_eventInstagram" type="url" />
                    <InputField label="Website" id="input_eventWebsite" type="url" />

                    <Divider />
                    <InputField label="Organiser's Name" id="input_organiserName" type="text" />
                    <InputField label="Organiser's Phone" id="input_organiserPhone" type="tel" />
                    <InputField label="Organiser's Email" id="input_organiserEmail" type="email" />
                    <InputField label="Organiser's Web" id="input_organiserWeb" type="url" />

                    <Divider />
                    <InputField label="Speakers and Guests" id="input_speakersGuests" type="textarea" />
                    <InputField label="Coordinators" id="input_coordinators" type="select" />

                    <Button label={ "Update Event" } />
                </FormContainer>
            </section>
        </LAYOUT_DEFAULT>
    )
}