// third-party components, hooks, libraries and packages
import { useEffect, useState } from "react";

// components
import { Divider, FormContainer, InputField } from "../components/form_elements.jsx";

// graphics
import ICON_CLOSE from "../images/arrow_left.svg"

// styles
import "../styles/forms.css"

export function FORM_ADD_COORDINATOR ({ callback, exit }) {
    // state holders
    const [ screenCount, setScreenCount ] = useState(0)
    const formData = useState({})

    // state watcher
    useEffect(() => {
        if (screenCount > 0) { document.querySelector(".form form")?.children[screenCount - 1]?.classList.toggle("in-view"); }

        document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
    }, [screenCount])

    // lambda
    const finish = () => {
        callback();
        return resetAndExit();
    }
    const cancel = () => { resetAndExit() }
    const resetAndExit = () => {
        if (screenCount > 0) {
            document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
            setScreenCount(0);
        }
        return exit()
    }

    return (
        <div className="form add_coordinator">
            <div className="head">
                <div>Add Coordinator</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div>       

            <FormContainer context={ formData }>
                <div className="screen in-view">
                    <div className="fields">
                        <InputField label="First Name" id="input_firstName" type="text" />
                        <InputField label="Last Name" id="input_lastName" type="text" />
                    </div>
                    <div className="controls" onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                </div>
                
                <div className="screen">
                    <div className="fields">
                        <InputField label="Phone" id="input_phone" type="tel" />
                        <InputField label="Email" id="input_email" type="email" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Add Coordinator</div>
                    </div>
                </div>

                <div className="screen">
                    <InputField label="Picture" id="input_picture" type="file" />
                    <div onClick={ () => finish() }>Add Picture</div>
                </div>            
            </FormContainer>
        </div>
    )
}

export function FORM_ADD_EVENT ({ callback, exit }) {
    // state holders
    const [ screenCount, setScreenCount ] = useState(0)
    const formData = useState({})

    // state watcher
    useEffect(() => {
        if (screenCount > 0) { document.querySelector(".form form")?.children[screenCount - 1]?.classList.toggle("in-view"); }

        document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
    }, [screenCount])

    // lambda
    const finish = () => {
        callback();
        return resetAndExit();
    }
    const cancel = () => { resetAndExit() }
    const resetAndExit = () => {
        if (screenCount > 0) {
            document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
            setScreenCount(0);
        }
        return exit()
    }

    return (
        <div className="form add_event">
            <div className="head">
                <div>Add Event</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div>

            <FormContainer context={ formData }>
                <div className="screen in-view">
                    <div className="fields">
                        <InputField label="Name" id="input_name" type="text" />
                        <InputField label="Venue" id="input_venue" type="select" />
                        <InputField label="Days Duration" id="input_dayDuration" type="number" />
                        <InputField label="Date(s)" id="input_date" type="textarea" />
                        <InputField label="Hashtag(s)" id="input_hashtag" type="text" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                    </div>
                </div>
                
                <div className="screen">
                    <div className="fields">
                        <InputField label="Event Phone" id="input_eventPhone" type="tel" />
                        <InputField label="Event Email" id="input_eventEmail" type="email" />
                        <InputField label="Twitter" id="input_eventTwitter" type="url" />
                        <InputField label="Instagram" id="input_eventInstagram" type="url" />
                        <InputField label="Website" id="input_eventWebsite" type="url" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                    </div>
                </div>
                
                <div className="screen">
                    <div className="fields">
                        <InputField label="Organiser's Name" id="input_organiserName" type="text" />
                        <InputField label="Organiser's Phone" id="input_organiserPhone" type="tel" />
                        <InputField label="Organiser's Email" id="input_organiserEmail" type="email" />
                        <InputField label="Organiser's Web" id="input_organiserWeb" type="url" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Add Event</div>
                    </div>
                </div>


                <div className="screen">
                    <div className="fields">
                        <InputField label="Event Logo" id="input_eventLogo" type="file" />
                        <InputField label="Event Flier" id="input_eventFlier" type="file" />
                        <InputField label="Coordinators" id="input_coordinators" type="select" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => finish() }>Update</div>
                    </div>
                </div>            
            </FormContainer>
        </div>
    )
}

export function FORM_ADD_VENDOR ({ callback, exit }) {
    // state holders
    const [ screenCount, setScreenCount ] = useState(0)
    const formData = useState({})

    // state watcher
    useEffect(() => {
        if (screenCount > 0) { document.querySelector(".form form")?.children[screenCount - 1]?.classList.toggle("in-view"); }

        document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
    }, [screenCount])

    // lambda
    const finish = () => {
        callback();
        return resetAndExit();
    }
    const cancel = () => { resetAndExit() }
    const resetAndExit = () => {
        if (screenCount > 0) {
            document.querySelector(".form form")?.children[screenCount]?.classList.toggle("in-view")
            setScreenCount(0);
        }
        return exit()
    }

    return (
        <div className="form add_vendor">
            <div className="head">
                <div>Add Vendor</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div>

            <FormContainer context={ formData }>
                <div className="screen in-view">
                    <div className="fields">
                        <InputField label="Business Name" id="input_businessName" type="text" />
                        <InputField label="Business Type" id="input_businessType" type="select" />
                    </div>
                    <div className="controls" onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                </div>
                
                <div className="screen">
                    <div className="fields">
                        <InputField label="Business Phone" id="input_businessPhone" type="tel" />
                        <InputField label="Business Email" id="input_businessEmail" type="email" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                    </div>
                </div>
                
                <div className="screen">
                    <div className="fields">
                        <InputField label="Twitter" id="input_businessTwitter" type="url" />
                        <InputField label="Instagram" id="input_businessInstagram" type="url" />
                        <InputField label="Website" id="input_businessWebsite" type="url" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Next</div>
                    </div>
                </div>

                <div className="screen">
                    <div className="fields">
                        <InputField label="Owner's Name" id="input_ownerName" type="text" />
                        <InputField label="Owner's Phone" id="input_ownerPhone" type="tel" />
                        <InputField label="Owner's Email" id="input_ownerEmail" type="email" />
                    </div>
                    <div className="controls">
                        <div onClick={ () => setScreenCount(screenCount - 1) }>Previous</div>
                        <div onClick={ () => setScreenCount(screenCount + 1) }>Add Vendor</div>
                    </div>
                </div>


                <div className="screen">
                    <InputField label="Business Logo" id="input_businessLogo" type="file" />
                    <div onClick={ finish }>Add Business Logo</div>
                </div>            
            </FormContainer>
        </div>
    )
}

export function FORM_EDIT_COORDINATOR ({ callback, exit }) {
    // state holders
    const formData = useState({})

    // lambda
    const finish = () => {
        callback();
        return exit();
    }
    const cancel = () => { return exit() }

    return (
        <div className="form add_coordinator">
            <div className="head">
                <div>Edit Coordinator</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div>      

            <FormContainer context={ formData }>
                <InputField label="First Name" id="input_firstName" type="text" />
                <InputField label="Last Name" id="input_lastName" type="text" />
                
                <Divider />
                <InputField label="Phone" id="input_phone" type="tel" />
                <InputField label="Email" id="input_email" type="email" />

                <Divider />
                <InputField label="Change Picture" id="input_picture" type="file" />

                <div onClick={ finish }>Update Coordinator</div>
            </FormContainer>
        </div>
    )
}

export function FORM_EDIT_EVENT ({ callback, exit }) {
    // state holders
    const formData = useState({})

    // lambda
    const finish = () => {
        callback();
        return exit();
    }
    const cancel = () => { return exit() }

    return (
        <div className="form add_event">
            <div className="head">
                <div>Edit Event</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div> 

            <FormContainer context={ formData }>
                <InputField label="Name" id="input_name" type="text" />
                <InputField label="Venue" id="input_venue" type="select" />
                <InputField label="Days Duration" id="input_dayDuration" type="number" />
                <InputField label="Date(s)" id="input_date" type="date" />
                <InputField label="Hashtag(s)" id="input_hashtag" type="text" />
                
                <Divider />
                <InputField label="Event Phone" id="input_eventPhone" type="tel" />
                <InputField label="Event Email" id="input_eventEmail" type="email" />
                <InputField label="Twitter" id="input_eventTwitter" type="url" />
                <InputField label="Instagram" id="input_eventInstagram" type="url" />
                <InputField label="Website" id="input_eventWebsite" type="url" />
                
                <Divider />
                <InputField label="Organiser's Name" id="input_organiserName" type="text" />
                <InputField label="Organiser's Phone" id="input_organiserPhone" type="tel" />
                <InputField label="Organiser's Email" id="input_organiserEmail" type="email" />
                <InputField label="Organiser's Web" id="input_organiserWeb" type="url" />

                <Divider />
                <InputField label="Event Logo" id="input_eventLogo" type="file" />
                <InputField label="Event Flier" id="input_eventFlier" type="file" />
                <InputField label="Coordinators" id="input_coordinators" type="select" />
                <div onClick={ finish }>Update Event</div>      
            </FormContainer>
        </div>
    )
}

export function FORM_EDIT_VENDOR ({ callback, exit }) {
    // state holders
    const formData = useState({})

    // lambda
    const finish = () => {
        callback();
        return exit();
    }
    const cancel = () => { return exit() }

    return (
        <div className="form add_vendor">
            <div className="head">
                <div>Edit Vendor</div>
                <div onClick={ cancel }><img src={ ICON_CLOSE } alt="close" /></div>
            </div> 

            <FormContainer context={ formData }>
                <InputField label="Business Name" id="input_businessName" type="text" />
                <InputField label="Business Type" id="input_businessType" type="select" />
                
                <Divider />
                <InputField label="Business Phone" id="input_businessPhone" type="tel" />
                <InputField label="Business Email" id="input_businessEmail" type="email" />
                
                <Divider />
                <InputField label="Twitter" id="input_businessTwitter" type="url" />
                <InputField label="Instagram" id="input_businessInstagram" type="url" />
                <InputField label="Website" id="input_businessWebsite" type="url" />
                
                <Divider />
                <InputField label="Owner's Name" id="input_ownerName" type="text" />
                <InputField label="Owner's Phone" id="input_ownerPhone" type="tel" />
                <InputField label="Owner's Email" id="input_ownerEmail" type="email" />
                
                <Divider />
                <InputField label="Business Logo" id="input_businessLogo" type="file" />
                <div onClick={ finish }>Update Vendor</div>
            </FormContainer>
        </div>
    )
}