// components
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// styles
import "../styles/page_product_details.css"

export default function PageProductDetails () {
    return (
        <LAYOUT_DEFAULT pageName="product_details">
            <section>
                <div>Product Details</div>
            </section>
        </LAYOUT_DEFAULT>
    )
}