// components
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// images
import mastercard_logo from "../images/mastercard_logo.png";
import mtn_mobile_money_logo from "../images/mtn_mobile_money_logo.png";
import visa_logo from "../images/visa_logo.png";
import vodafone_cash_logo from "../images/vodafone_cash_logo.png";

// styles
import "../styles/page_payments.css"

export default function PagePayments () {
    return (
        <LAYOUT_DEFAULT pageName="payments">
            <section>
                <div id="collection-head">
                    <div className="title">Payments</div>
                </div>

                <div id="collection" className="list">
                    <div className="title">Cards</div>
                    <div className="payment scard">
                        <div className="vendor"><img src={ visa_logo } alt="" /></div>
                        <div className="number">**** **** **** ****</div>
                        <div className="expiry">10/24</div>
                    </div>
                    <div className="payment scard">
                        <div className="vendor"><img src={ mastercard_logo } alt="" /></div>
                        <div className="number">**** **** **** ****</div>
                        <div className="expiry">10/24</div>
                    </div>
                </div>
                
                <div id="collection" className="list">
                    <div className="title">Mobile Wallets</div>
                    <div className="payment mwallet">
                        <div className="vendor"><img src={ vodafone_cash_logo } alt="" /></div>
                        <div className="number">*** *** ****</div>
                    </div>
                    <div className="payment mwallet">
                        <div className="vendor"><img src={ mtn_mobile_money_logo } alt="" /></div>
                        <div className="number">*** *** ****</div>
                    </div>
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}