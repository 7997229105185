// third-party components, hooks, libraries and packages
import { Link } from "react-router-dom";

// components
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { CARD_EVENT } from "../components/cards.jsx";
import { NAV_RIBBON, NAV_SWITCH_BAR } from "../components/navs.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_attendee_details.css"

export default function PageAttendeeDetails () {
    // functions
    const switchEvents = () => { console.log("switched events"); }

    return (
        <LAYOUT_DEFAULT pageName="attendee_details">
            <section>
                <NAV_RIBBON>
                    <Link to={ { pathname: "/attendee_details" } }><div className="tab">Summary</div></Link>
                    <Link to={ { pathname: "/purchases" } }><div className="tab">Purchases</div></Link>
                    <Link to={ { pathname: "/sessions" } }><div className="tab">Sessions</div></Link>
                </NAV_RIBBON>
                
                <div className="id-brief">
                    <div className="name">Attendee Name</div>
                    <div className="controls">
                        <div className="id">1209234</div>
                        <div className="status">Status</div>
                    </div>
                </div>

                <NAV_SWITCH_BAR options={ [
                    {
                        name: "Upcoming Events",
                        id: "0"
                    }, {
                        name: "Past Events",
                        id: "1"
                    },
                ] }
                defaultIndex={ 0 } callback={ switchEvents } />

                <div id="collection" className="grid">
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}