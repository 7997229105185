// third-party components, hooks, libraries and packages
import { Link } from "react-router-dom";

// components
import { CARD_EVENT } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_RIBBON } from "../components/navs.jsx";
import { FORM_ADD_EVENT } from "../components/forms.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_events.css"

export default function PageEvents () {
    // lamdda
    const search = (action, query) => {
        action.preventDefault();
        console.log(query);
    }
    const toggleOther = () => { document.querySelector(".layout.default")?.classList?.toggle("show-pannel") }
    const addEvent = () => { console.log("Adding event"); }

    return (
        <LAYOUT_DEFAULT pageName="events" type="panneled">
            <section id="main">
                <NAV_RIBBON searchBar={ true } searchBarPlaceholder="Search by name, date, or tags" searchBarCallback={ search }>
                    <div className="tab">Ongoing</div>
                    <div className="tab">Upcoming</div>
                    <div className="tab">Past</div>
                </NAV_RIBBON>
                
                <div id="collection-head">
                    <div className="title">Events</div>
                    <div className="cta" onClick={ () => toggleOther() }>Add Event</div>
                </div>

                <div id="collection" className="grid">
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                </div>
            </section>

            <section id="pannel">
                <FORM_ADD_EVENT callback={ addEvent } exit={ toggleOther } />
            </section>
        </LAYOUT_DEFAULT>
    )
}