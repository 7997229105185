export const NAV = [
    {   "name": "Dashboard",
        "path": "/dashboard",
        "id": "dashboard"
    }, {
        "name": "Events",
        "path": "/events",
        "id": "events",
        "subs": [
            {   "name": "Past Event",
                "path": "/events/:past",
                "id": "events_past"
            }, {
                "name": "Upcoming Event",
                "path": "/events/:upcoming",
                "id": "events_upcoming"
            }, 
        ]
    }, {
        "name": "Vendors",
        "path": "/vendors",
        "id": "vendors",
        "subs": [
            {   "name": "Active Vendors",
                "path": "/vendors/:active",
                "id": "vendors_active"
            },  {
                "name": "Inactive Vendors",
                "path": "/vendors/:inactive",
                "id": "vendors_inactive"
            },  {
                "name": "Food Vendors",
                "path": "/vendors/:food",
                "id": "vendors_food"
            },  {
                "name": "Clothing Vendors",
                "path": "/vendors/:clothing",
                "id": "vendors_clothing"
            }
        ]
    }, {
        "name": "Attendees",
        "path": "/attendees",
        "id": "attendees",
        "subs": [
            {   "name": "Active Attendees",
                "path": "/attendees/:active",
                "id": "attendees_active"
            },  {
                "name": "Inactive Attendees",
                "path": "/attendees/:inactive",
                "id": "attendees_inactive"
            },
        ]
    }, {
        "name": "Coordinators",
        "path": "/coordinators",
        "id": "coordinators",
        "subs": [
            {   "name": "Active Coordinators",
                "path": "/coordinators/:active",
                "id": "coordinators_active"
            },  {
                "name": "Inactive Coordinators",
                "path": "/coordinators/:inactive",
                "id": "coordinators_inactive"
            },
        ]
    }, {
        "name": "Transactions",
        "path": "/transactions",
        "id": "transactions",
        "subs": [
            {   "name": "Successful Transactions",
                "path": "/transactions/:successful",
                "id": "transactions_successful"
            },  {
                "name": "Pending Transactions",
                "path": "/transactions/:pending",
                "id": "transactions_pending"
            },  {
                "name": "Failed Transactions",
                "path": "/transactions/:failed",
                "id": "transactions_failed"
            },
        ]
    }
]