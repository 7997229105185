// components
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";
import { NAV_RIBBON } from "../components/navs.jsx";
import { CARD_VENDOR } from "../components/cards.jsx";
import { FORM_ADD_VENDOR } from "../components/forms.jsx";

// images
import HOLDING_IMAGE from "../images/PXL_20220909_235830633.PORTRAIT.jpg"

// styles
import "../styles/page_vendors.css"

export default function PageVendors () {
    // lamdda
    const search = (action, query) => {
        action.preventDefault();
        console.log(query);
    }
    const toggleOther = () => { document.querySelector(".layout.default")?.classList?.toggle("show-pannel") }
    const addVendor = () => { "Adding Vendor" }

    return (
        <LAYOUT_DEFAULT pageName="vendors" type="panneled">
            <section id="main">
                <NAV_RIBBON searchBar={ true } searchBarPlaceholder="Search by name" searchBarCallback={ search }>
                    <div className="tab">Food</div>
                    <div className="tab">Clothing</div>
                    <div className="tab">Active</div>
                    <div className="tab">Inactive</div>
                </NAV_RIBBON>

                <div id="collection-head">
                    <div className="title">Vendor</div>
                    <div className="cta" onClick={ () => toggleOther() }>Add Vendor</div>
                </div>

                <div id="collection" className="grid">
                    <CARD_VENDOR name={ "Vendor Name" } category={ "Food" } id={ "1" } image={ HOLDING_IMAGE } web={ "https://www.google.com" } instagram={ "https://www.instagram.com" } twitter={ "https://www.x.com" }  />
                    <CARD_VENDOR name={ "Vendor Name" } category={ "Food" } id={ "1" } image={ HOLDING_IMAGE } web={ "https://www.google.com" } instagram={ "https://www.instagram.com" } twitter={ "https://www.x.com" }  />
                    <CARD_VENDOR name={ "Vendor Name" } category={ "Food" } id={ "1" } image={ HOLDING_IMAGE } web={ "https://www.google.com" } instagram={ "https://www.instagram.com" } twitter={ "https://www.x.com" }  />
                    <CARD_VENDOR name={ "Vendor Name" } category={ "Food" } id={ "1" } image={ HOLDING_IMAGE } web={ "https://www.google.com" } instagram={ "https://www.instagram.com" } twitter={ "https://www.x.com" }  />
                </div>
            </section>

            <section id="pannel">
                <FORM_ADD_VENDOR callback={ addVendor } exit={ toggleOther } />
            </section>
        </LAYOUT_DEFAULT>
    )
}