// third-party components, hooks, libraries and packages
import { useState } from "react";
import { Link } from "react-router-dom";

// components
import { LAYOUT_AUTH } from "../components/layouts.jsx";
import { FormContainer, InputField } from "../components/form_elements.jsx";

// styles
import "../styles/page_log_in.css"

export default function PageLogIn () {
    // state holders
    const context = useState({})

    return (
        <LAYOUT_AUTH pageName="log_in">
            <section>
                <div id="form">
                    <div>Log in</div>
                    <FormContainer context={ context }>
                        <InputField type={ "text" } label={ "Username" } name={ "input_username" } id={ "input_username" } required={ true } />
                        <InputField type={ "password" } label={ "Password" } name={ "input_password" } id={ "input_password" } required={ true } />
                        <Link to={ { pathname: "/dashboard" } }>Log In</Link>
                    </FormContainer>
                </div>
            </section>
        </LAYOUT_AUTH>
    )
}