// components
import { CARD_PURCHASES } from "../components/cards.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"

// styles
import "../styles/page_purchases.css"

export default function PagePurchases () {

    return (
        <LAYOUT_DEFAULT pageName="purchases">
            <section>
                <div id="collection-head">
                    <div className="title">Purchases</div>
                </div>
                <div id="collection" className="list">
                    <CARD_PURCHASES image={ HOLDING_IMAGE } product_name={ "Product Name" } price={ "$ 15.95" } quantity={ "81" } total={ "$ 1291.95" } amount_paid={ "$ 1291.95" } event_name={ "Cleva Launch" } datetime={ "12/12/12, 12:12" } />
                    <CARD_PURCHASES image={ HOLDING_IMAGE } product_name={ "Product Name" } price={ "$ 15.95" } quantity={ "81" } total={ "$ 1291.95" } amount_paid={ "$ 1291.95" } event_name={ "Cleva Launch" } datetime={ "12/12/12, 12:12" } />
                </div>
            </section>
        </LAYOUT_DEFAULT>
    )
}