// components
import { FORM_EDIT_COORDINATOR } from "../components/forms.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// images
import HOLDING_IMAGE from "../images/PXL_20220909_235830633.PORTRAIT.jpg";

// styles
import "../styles/page_coordinator_details.css"

export default function PageCoordinatorDetails () {
    // lambda
    const toggleContentPannel = () => { document.querySelector(".layout.default")?.classList?.toggle("show-pannel") }

    return (
        <LAYOUT_DEFAULT pageName="coordinator_details" type="panneled">
            <section id="main">
                <div className="title">Coordinator Details</div>
                <div className="picture"><img src={ HOLDING_IMAGE } alt="" /></div>

                <div className="id-brief">
                    <div className="name">Coordinator Name</div>
                    <div className="controls">
                        <div className="id">1002334656</div>
                        <div className="status">Status</div>
                        <div className="cta" onClick={ () => toggleContentPannel() }>Update/ Edit</div>
                    </div>
                </div>
                
                <div className="contact">
                    <div className="title">Contact</div>
                    <div>
                        <div>(+1) 234 567 890</div>
                        <div>email@email.com</div>
                    </div>
                </div>
            </section>
            
            <section id="pannel">
                <FORM_EDIT_COORDINATOR callback={ toggleContentPannel } exit={ toggleContentPannel } />
            </section>
        </LAYOUT_DEFAULT>
    )
}