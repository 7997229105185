// components
import { NAV_DYNAMIC } from "./navs"

// styles
import "../styles/layouts.css"

export function LAYOUT_AUTH (props) {
    return (
        <div className={ `layout auth` } data-page-name={ props?.pageName }>
            <div id="content">
                { props?.children }
            </div>
        </div>
    )
}

export function LAYOUT_DEFAULT (props) {
    return (
        <div className={ props?.type === `panneled` ? `layout default panneled` : `layout default` } data-page-name={ props?.pageName }>
            <div id="nav">
                <NAV_DYNAMIC links={ [
                    {
                        name: "Dashboard",
                        path: "/"
                    },
                    {
                        name: "Events",
                        path: "/events"
                    },
                    {
                        name: "Vendors",
                        path: "/events"
                    }
                ] } />
            </div>
            <div id="content">
                { props?.children }
            </div>
        </div>
    )
}