//third-party components and hooks
import { createContext, useContext } from "react";

// styles
import "../styles/form_elements.css"

// contexts
export const FormContext = createContext();

export function FormContainer ({ children, context }) {
    return (
        <FormContext.Provider value={ context }>
            <form>{ children }</form>
        </FormContext.Provider>
    )
}

// button
export function Button ({ callback, label }) {
    const button_click_handler = (e) => {
        e.preventDefault();
        callback();
    }

    return (
        <button onClick={ (e) => button_click_handler(e) } className="button">{ label }</button>
    )
}

// divider
export function Divider () {
    return (
        <div className="divider"></div>
    )
}

// input field
export function InputField ({ id, label, name, options, required, type }) {
    const [ formData, updateFormData ] = useContext(FormContext)

    // const fileUploader = (e) => {
    //     e.preventDefault();
      
    //     if (e?.target?.files && e.target.files.length > 0) {
    //       const files = Array.from(e.target.files);
      
    //       // Process each file
    //       files.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onload = (ev) => {
    //           setPreviewImages((prevState) => [...prevState, ev.target.result]);
    //         };
    //         setSelectedImages((prevState) => [...prevState, file]);
      
    //         reader.readAsDataURL(file);
    //       });
    //     }
    //   };

    return (
        <div className="input-field">
            { label ? <label htmlFor={ label }>{ label }</label> : null }

            {
                type === `radio` ?
                <div className="options">
                    {
                        options?.map((option, key) => {
                            return(
                                <div key={ key } className={ formData?.data && formData?.data[name] === option ? `selected` : null } onClick={ () => updateFormData({ ...formData, data: { ...formData?.data, [ name ]: option } }) }>{ option }</div>
                            )
                        })
                    }
                </div> : null
            }
            {
                
                type === `textarea` ?
                <textarea name={ name } id="id" required={ required } onChange={ ({ target }) => updateFormData({ ...formData, data: { ...formData?.data, [ target?.name ]: target?.value } }) }></textarea> :
                <>
                    {
                        type === `file` ?
                        <div onClick={ () => { document.getElementById(id).click() } } className="file-uploader">
                            Upload File
                        </div> : null
                    }
                    <input type={ type } name={ name } id={ id } required={ required } onChange={ ({ target }) => updateFormData({ ...formData, data: { ...formData?.data, [ target?.name ]: target?.value } }) } />
                </>
            }
        </div>
    )
}

// Intro component
// contains the introductory message at the start/ before the form input fields 
export function Intro ({ message }) {
    return (
        <div className="intro">{ message }</div>
    )
}