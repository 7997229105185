// third-party components, hooks, libraries and packages
import { Link } from "react-router-dom"

// images
import HOLDING_IMAGE from "../images/wear_ghana_festival_24_logo.svg"
import ICON_GLOBE from "../images/globe.svg"
import ICON_INSTAGRAM from "../images/instagram.svg"
import ICON_TRASH from "../images/trash.svg"
import ICON_TWIITER from "../images/twitter.svg"

// styles
import "../styles/cards.css"

export function CARD_ATTENDEE (props) {
    // functions
    const toggleStatus = (id) => { console.log("toggling attendee details"); }

    return (
        <div className="card attendee">
            <div className="image"><img src={ props?.image } alt="attendee" /></div>
            <div className="details">
                <Link to={ { pathname: "/attendee_details" } }><div className="name">{ props?.name }</div></Link>
                <div className="controls">
                    <div className="status" onClick={ () => toggleStatus(props?.id) }>Activate</div>
                </div>
            </div>
        </div>
    )
}

export function CARD_CHART (props) {
    return (
        <div className="card chart">
            <div>Chart</div>
        </div>
    )
}

export function CARD_COORDINATOR (props) {
    // functions
    const deleteCoordinator = (id) => { console.log("deleting coordinator"); }
    const toggleStatus = (id) => { console.log("toggling coordinator details"); }

    return (
        <div className="card coordinator">
            <div className="image"><img src={ props?.image } alt="coordinator" /></div>
            <div className="details">
                <Link to={ { pathname: "/coordinator_details" } }><div className="name">{ props?.name }</div></Link>
                <div className="controls">
                    <div className="status" onClick={ () => toggleStatus(props?.id) }>Activate</div>
                    <div className="delete" onClick={ () => deleteCoordinator(props?.id) }>Delete</div>
                </div>
            </div>
        </div>
    )
}

export function CARD_EVENT (props) {
    return (
        <div className="card event">
            <div className="date">{ props?.date }</div>
            <div className="venue">{ props?.venue }</div>
            <div className="flier">
                <div className="banner"><img src={ props?.image } alt="banner" /></div>
                <div className="name">{ props?.name }</div>
            </div>
        </div>
    )
}

export function CARD_EVENT_FLIER (props) {
    return (
        <div className="card event-flier" style={ { "--backdrop": props?.image } }><img src={ props?.image } alt="event" /></div>
    )
}

export function CARD_EVENT_TICKET_SALES (props) {
    return (
        <div className="card event-ticket-sales">
            <div className="image"><img src={ HOLDING_IMAGE } alt="event" /></div>
            <div className="details">
                <div className="name">{ props?.name }</div>
                <div className="tickets">{ props?.tickets_sold } tickets sold</div>
            </div>
        </div>
    )
}

export function CARD_ORGANISER (props) {
    return (
        <div className="card organiser">
            <img src={ props?.logo } title={ props?.name } alt={ props?.name } />
        </div>
    )
}

export function CARD_PRODUCT_AD (props) {
    return (
        <div className="card product-ad">
            <div className="image"><img src={ props?.image } alt="" /></div>
            <div className="description">
                <div className="brief">{ props?.brief }</div>
                <div className="name">{ props?.name }</div>
            </div>
        </div>
    )
}

export function CARD_PRODUCT_RECORD (props) {
    return (
        <div className="card product-record">
            <div className="image"><img src={ props?.image } alt="item" /></div>
            <div className="text">
                <div className="name">{ props?.name }</div>
                <div className="date">
                    <div>{ props?.type === "update" ? `Updated on: ${ props?.date }` : `Added on: ${ props?.date }` }</div>
                </div>
            </div>
        </div>
    )
}

export function CARD_PRODUCT (props) {
    return (
        <div className="card product">
            <div className="image"><img src={ props?.image } alt="item" /></div>
            <div className="briefing">
                <div className="name">{ props?.name }</div>
                <div className="price-n-inventory">
                    <div>{ props?.price }</div>
                    <div>{ props?.inventory }</div>
                </div>
            </div>
        </div>
    )
}

export function CARD_PURCHASES (props) {
    return (
        <div className="card purchase">
            <div className="image"><img src={ props?.image } alt="product" /></div>
            <div className="text">
                <div className="product-name">
                    <span>Product Name:</span>
                    { props?.product_name }
                </div>
                <div className="price">
                    <span>Price:</span>
                    { props?.price }
                </div>
                <div className="quantity">
                    <span>Quantity:</span>
                    { props?.quantity }
                </div>
                <div className="total">
                    <span>Total:</span>
                    { props?.total }
                </div>
                <div className="paid">
                    <span>Paid:</span>
                    { props?.amount_paid }
                </div>
                <div className="event-name">
                    <span>Purchased At:</span>
                    { props?.event_name }
                </div>
                <div className="datetime">
                    <span>Date/Time:</span>
                    { props?.datetime }
                </div>
            </div>
        </div>
    )
} 

export function CARD_SESSION (props) {
    return (
        <div className={ `card session ${ props?.heading ? "heading" : null }` }>
            <div className="datetime">{ props?.heading ? `DateTime` : props?.datetime }</div>
            <div className="location">{ props?.heading ? `Location` : props?.location }</div>
            <div className="client">{ props?.heading ? `Client` : props?.client }</div>
            <div className="duration">{ props?.heading ? `Duration` : props?.duration }</div>
        </div>
    )
}

export function CARD_SPEAKER (props) {
    return (
        <div className="card speaker">
            <div className="image"><img src={ props?.image } alt="speaker" /></div>
            <div className="text">
                <div className="name">{ props?.name }</div>
                <div className="organisation">{ props?.organisation }</div>
            </div>
        </div>
    )
}

export function CARD_TRANSACTION (props) {
    return (
        <div className={ `card transaction ${ props?.heading ? "heading" : null }` }>
            <div className="id">{ props?.heading ? `ID` : props?.id }</div>
            <div className="datetime">{ props?.heading ? `DateTime` : props?.datetime }</div>
            <div className="amount">{ props?.heading ? `Amount` : props?.amount }</div>
            <div className="sender">{ props?.heading ? `Sender` : props?.sender }</div>
            <div className="receiver">{ props?.heading ? `Receiver` : props?.receiver }</div>
            <div className="status">{ props?.heading ? `Status` : props?.status }</div>
            <div className="reason">{ props?.heading ? `Reason` : props?.reason }</div>
        </div>
    )
}

export function CARD_USER (props) {
    return (
        <div  className="card user">
            <div className="profile-image"><img src={ props?.image } alt="profile" /></div>
            <div className="brief-and-controls">
                <div className="name">{ props?.name }</div>
                <div className="cta" onClick={ props?.callback }>Sign Out</div>
            </div>
        </div>
    )
}

export function CARD_VENDOR (props) {
    // functions
    const deleteVendor = (id) => { console.log("Deleting the vendor"); }
    const toggleStatus = (id) => { console.log("toggling vendor status"); }

    return (
        <div className="card vendor">
            <Link className="details" to={ { pathname: "/vendor_details" } }>
                <div className="logo"><img src={ props?.image } alt="logo" /></div>
                <div className="name">{ props?.name }</div>
                <div className="category">{ props?.category }</div>
            </Link>

            <div className="handles">
                <div className="socials">
                    <Link to={ props?.web } className="web" target="_blank"><img src={ ICON_GLOBE } alt="URL" /></Link>
                    <Link to={ props?.instagram } className="instagram" target="_blank"><img src={ ICON_INSTAGRAM } alt="URL" /></Link>
                    <Link to={ props?.twitter } className="twitter" target="_blank"><img src={ ICON_TWIITER } alt="URL" /></Link>
                </div>

                <div className="controls">
                    <div className="status" onClick={ () => toggleStatus(props?.id) }></div>
                    <div className="delete" onClick={ () => deleteVendor(props?.id) }><img src={ ICON_TRASH } alt="URL" /></div>
                </div>
            </div>
        </div>
    )
}