// third-party components, hooks, libraries and packages
import { Link } from "react-router-dom";

// components
import { LAYOUT_DEFAULT } from "../components/layouts";
import { CARD_CHART, CARD_EVENT, CARD_PRODUCT_AD } from "../components/cards";
import { NAV_RIBBON, NAV_SWITCH_BAR } from "../components/navs";
import { FORM_EDIT_VENDOR } from "../components/forms";

// images
import HOLDING_IMAGE from "../images/PXL_20220909_235830633.PORTRAIT.jpg"

// styles
import "../styles/page_vendor_details.css"

export default function PageVendorDetails (props) {
    // lambda
    const toggleContentPannel = () => { document.querySelector(".layout.default")?.classList?.toggle("show-pannel") }
    const switchEvents = () => { console.log("switched events"); }
    const updateVendor = () => { "Updating Vendor" }

    return (
        <LAYOUT_DEFAULT pageName="vendor_details" type="panneled">
            <section id="main">
                <NAV_RIBBON>
                    <div><Link to={ { pathname: "/vendor_details" } }>Summary</Link></div>
                    <div><Link to={ { pathname: "/products" } }>Products</Link></div>
                    <div><Link to={ { pathname: "/payments" } }>Payment</Link></div>
                    <div><Link to={ { pathname: "/sessions" } }>Sessions</Link></div>
                </NAV_RIBBON>

                <div className="id-brief">
                    <div className="name">Vendor Name</div>
                    <div className="controls">
                        <div className="id">1002334656</div>
                        <div className="status">Status</div>
                        <div className="cta" onClick={ () => toggleContentPannel() }>Update/ Edit</div>
                    </div>
                </div>

                <div className="stats">
                    <div className="title">Statistics</div>
                    <div className="summary">
                        <div className="chart">
                            <CARD_CHART />
                        </div>
                        <div className="chart"> <CARD_CHART /></div>
                        <div className="chart"> <CARD_CHART /></div>
                    </div>
                </div>

                <div className="quick-products">
                    <div className="title">Products</div>
                    <div className="summary">
                        <div className="newest">
                            <CARD_PRODUCT_AD image={ HOLDING_IMAGE } name={ "Product X" } brief={ "The Newest Product" } />
                        </div>
                        <div className="top">
                            <CARD_PRODUCT_AD image={ HOLDING_IMAGE } name={ "Product X" } brief={ "Top Selling Product" } />
                        </div>
                        <div className="updated">
                            <CARD_PRODUCT_AD image={ HOLDING_IMAGE } name={ "Product A" } brief={ "Updated Product" } />
                        </div>
                    </div>
                </div>

                <NAV_SWITCH_BAR options={ [
                    {
                        name: "Upcoming Events",
                        id: "0"
                    }, {
                        name: "Past Events",
                        id: "1"
                    },
                ] }
                defaultIndex={ 0 } callback={ switchEvents } />

                <div id="collection" className="grid">
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                    <Link to={ { pathname: "/event_details" } }><CARD_EVENT date={ "25/ 12/ 2023" } image={ HOLDING_IMAGE } venue={ "Someplace" } name={ "Cleva Launch" } /></Link>
                </div>
            </section>

            <section id="pannel">
                <FORM_EDIT_VENDOR callback={ updateVendor } exit={ toggleContentPannel } />
            </section>
        </LAYOUT_DEFAULT>
    )
}