// third-party components, hooks, libraries and packages
import { useState } from "react";

// components
import { Button, Divider, FormContainer, InputField } from "../components/form_elements.jsx";
import { LAYOUT_DEFAULT } from "../components/layouts.jsx";

// styles
import "../styles/page_edit_vendor.css"

export default function PageEditVendor () {
    // state holders
    const formData = useState({})

    return (
        <LAYOUT_DEFAULT pageName="edit_vendor">
            <section>
                <div className="title">Edit vendor</div>

                <FormContainer context={ formData }>
                    <InputField label="Business Name" id="input_businessName" type="text" />
                    <InputField label="Business Type" id="input_businessType" type="select" />
                    <InputField label="Business Logo" id="input_businessLogo" type="file" />
                    
                    <Divider />
                    <div className="input-group">
                        <InputField label="Business Phone" id="input_businessPhone" type="tel" />
                        <InputField label="Business Email" id="input_businessEmail" type="email" />
                    </div>
                    <InputField label="Twitter" id="input_businessTwitter" type="url" />
                    <InputField label="Instagram" id="input_businessInstagram" type="url" />
                    <InputField label="Website" id="input_businessWebsite" type="url" />

                    <Divider />
                    <InputField label="Owner's Name" id="input_ownerName" type="text" />
                    <div className="input-group">
                        <InputField label="Owner's Phone" id="input_ownerPhone" type="tel" />
                        <InputField label="Owner's Email" id="input_ownerEmail" type="email" />
                    </div>
                    
                    <Button label={ "Edit Vendor" } />
                </FormContainer>
            </section>
        </LAYOUT_DEFAULT>
    )
}